export const GET_TRAININGGROUPS = 'GET_TRAININGGROUPS';
export const ADD_TRAININGGROUP = 'ADD_TRAININGGROUP';
export const UPDATE_TRAININGGROUP = 'UPDATE_TRAININGGROUP';
export const FILTER_TRAININGGROUPS = 'FILTER_TRAININGGROUPS';
export const CLEAR_TRAININGGROUPS = 'CLEAR_TRAININGGROUPS';
export const DELETE_TRAININGGROUP = 'DELETE_TRAININGGROUP';

export const GET_MEMBERS = 'GET_MEMBERS';
export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const FILTER_MEMBERS = 'FILTER_MEMBERS';
export const CLEAR_MEMBERS = 'CLEAR_MEMBERS';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const GET_EMAILS = 'GET_EMAILS';
export const ADD_EMAIL = 'ADD_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const FILTER_EMAILS = 'FILTER_EMAILS';
export const CLEAR_EMAILS = 'CLEAR_EMAILS';
export const DELETE_EMAIL = 'DELETE_EMAIL';

export const GET_TRAININGSESSIONS = 'GET_TRAININGSESSIONS';
export const ADD_TRAININGSESSION = 'ADD_TRAININGSESSION';
export const UPDATE_TRAININGSESSION = 'UPDATE_TRAININGSESSION';
export const FILTER_TRAININGSESSIONS = 'FILTER_TRAININGSESSIONS';
export const CLEAR_TRAININGSESSIONS = 'CLEAR_TRAININGSESSIONS';
export const DELETE_TRAININGSESSION = 'DELETE_TRAININGSESSION';

export const SET_CURRENT = 'SET_CURRENT';
export const TRAININGSESSION_ERROR = 'TRAININGSESSION_ERROR';
export const TRAININGGROUP_ERROR = 'TRAININGGROUP_ERROR';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const EMAIL_ERROR = 'EMAIL_ERROR';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS_ALERT';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const MEMBER_LOADED = 'MEMBER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
